.hide-141-104-ads {
    display: none;
}

#ads-pos-101, div[id^=ads-pos-101_] {
    display: none;
}

div[id^="ads-pos-141"] {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
}

div[id^="ads-pos-1122"] {
    margin-top: 10px;
    margin-bottom: 10px;
}

.stickHeader {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #fff;
    z-index: 500
}

#adtagheader.stickHeader {
    margin-top: 0
}

.unStickHeader {
    position: relative;
    background-color: #fff;
    top: auto
}

.mobile_adlabelleft.stickyHeader {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    height: 60px
}

.stickyHeader #article-nav.fixed-sticky {
    top: 60px
}

.ifi-thumb {
    width: 72px !important;
    height: 54px !important;
    border: none;
    float: left !important;
    display: block !important;
    margin: 0 !important;
}

.ifi-title {
    color: #000;
}
